










































import { CommonRes } from "@/api/common";
import { updatePassword, UpdatePasswordReq } from "@/api/user";
import { Component, Vue } from "vue-property-decorator";
import { Toast } from "vant";
import { checkPassword } from "@/utils/utils";
@Component({
  components: {},
})
export default class Index extends Vue {
  private oldPassword: string = "";
  private newPassword: string = "";
  private confirmPassword: string = "";
  created() {
    console.log("updatePassword created");
  }
  private async confirmUpdatePassword() {
    if (this.oldPassword.length < 6) {
      Toast(this.$t("oldPasswordShort").toString());
      return;
    }
    if (!checkPassword(this.newPassword)) {
      Toast(this.$t("passwordError").toString());
      return;
    }
    if (this.newPassword != this.confirmPassword) {
      Toast(this.$t("passwordDiffer").toString());
      return;
    }
    if (this.oldPassword == this.newPassword) {
      Toast(this.$t("newOldPasswordSame").toString());
      return;
    }
    let req: UpdatePasswordReq = {
      oldPassword: this.oldPassword,
      password: this.newPassword,
    };
    let res: CommonRes = await updatePassword(req);
    if (res.code == 0) {
      let savePassword = localStorage.getItem("savePassword");
      if (savePassword != null) {
        //localStorage.setItem("password", this.newPassword);
      }
      Toast(this.$t("updatePasswordOk").toString());
      this.$router.go(-1);
    }
  }
}
